@import "../../../Scss/index";

.btn {
  padding: 0rem;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  .button {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    padding-inline: 11px;
    background: transparent;
    font-family: $GudeaBold;
    font-size: 14px;
    color: $SecondaryBgColor;
    border: none;
    &:hover {
      cursor: pointer;
      background-color: $LinkHover;
      border-radius: 10px;
      .arrowicon {
        transform: translateX(20%);
      }
    }
    &:focus {
      outline: none;
    }
    .btncontent {
      @include DisplayFlexCenter(Row);
      justify-content: left;
      margin: 0rem;
      width: fit-content;
      .btntext {
        @include DisplayFlexCenter(row);
        height: 30px;
        margin: 0;
        padding-right: 7px;
        margin-right: 6px;
        width: fit-content;
      }
      .arrowicon {
        transition: all 0.2s ease-in-out;
        width: 22px;
        height: 22px;
      }
    }
  }
}
