@import "../../../../Scss/index";

.postion {
  top: 13%;
  left: 9%;
  transform: translate(-50%, -50%);
  @include media-breakpoint-min($breakpoints-xs) {
    z-index: 900;
    position: fixed;
    top: 7rem;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media-breakpoint-min($breakpoints-xxl) {
    top: 93%;
    left: 90%;
  }
  .container {
    @include media-breakpoint-min($breakpoints-xs) {
    }
    @include media-breakpoint-min($breakpoints-xxl) {
    }
    .body {
      position: relative;
      @include DisplayFlexCenter(row);
      justify-content: flex-start;
      width: 14rem;
      height: 2rem;
      background-color: $PrimaryBgColor;
      border-radius: 2px;
      // border: 1px solid $BorderColor;
      padding: 0.75rem;
      padding-bottom: 0.9rem;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      .donticon {
        position: absolute;
        right: 0.75rem;
        margin-top: 0.2rem;
        padding-right: 0.2rem;
        width: 24px;
        height: 24px;
      }
      .text {
        margin: 0;
        color: $PrimaryNavTextColor;
        font-family: $GudeaRegular;
        padding-left: 0.25rem;
      }
      .img {
        width: 24px;
        height: 24px;
        padding-right: 0.75rem;
      }
    }
    .loadingbar {
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: $PrimaryRedLinear;
      border-radius: 4px;
      //   animation: loadingSlide 2s linear infinite;
    }
  }
}

@keyframes loadingSlide {
  0% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}
