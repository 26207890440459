@import "../../Scss/index";

.container {
  @include Container();
  @include DisplayFlexCenter(row);
  height: 100%;
  padding-top: 8rem;
  @include media-breakpoint-min($breakpoints-xs) {
    & {
      padding-top: 6rem;
    }
  }
  @include media-breakpoint-min($breakpoints-xl) {
    & {
      height: 100vh;
      padding-top: 0rem;
    }
  }
  .row {
    @include make-row();
    @include media-breakpoint-min($breakpoints-xs) {
      & {
        flex-direction: column-reverse;
      }
    }
    @include media-breakpoint-min($breakpoints-xl) {
      & {
        flex-direction: row;
      }
    }
    .col_8 {
      @include make-col(xs, 12);
      @include make-col(sm, 12);
      @include make-col(md, 12);
      @include make-col(lg, 12);
      @include make-col(xl, 7);
      @include make-col(xxl, 7);
      @include DisplayFlexCenter(row);
      justify-content: flex-end;
    }
    .col_4 {
      @include make-col(xs, 12);
      @include make-col(sm, 12);
      @include make-col(md, 12);
      @include make-col(xl, 5);
      @include make-col(xxl, 5);
      @include DisplayFlexCenter(row);
      .imgcontent {
        position: relative;
        width: 100%;
        object-fit: contain;
        .imgcontainer {
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -32.3%);
          @include media-breakpoint-min($breakpoints-xs) {
            & {
              transform: translate(-50%, -45.7%);
            }
          }
          @include media-breakpoint-min($breakpoints-xl) {
            & {
              transform: translate(-50%, -32.3%);
            }
          }
          .img {
            position: relative;
            width: 22rem;
            height: 22rem;
            object-fit: contain;
            @include media-breakpoint-min($breakpoints-xs) {
              & {
                width: 10rem;
              }
            }
            @include media-breakpoint-min($breakpoints-xl) {
              & {
                width: 22rem;
              }
            }
          }
        }
        .colorbg {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
          height: 100%;
          width: 100%;
          .imgbackground {
            content: "";
            position: relative;
            background-color: $SecondaryBgColor;
            width: 50%;
            height: 29.6rem;
            @include DisplayFlexCenter(row);
            align-self: center;
            @include media-breakpoint-min($breakpoints-xs) {
              & {
                height: 17rem;
                width: 30%;
              }
            }
            @include media-breakpoint-min($breakpoints-sm) {
              & {
                height: 17rem;
                width: 30%;
              }
            }
            @include media-breakpoint-min($breakpoints-md) {
              & {
                height: 17rem;
                width: 20%;
              }
            }
            @include media-breakpoint-min($breakpoints-lg) {
              & {
                height: 17rem;
                width: 15%;
              }
            }
            @include media-breakpoint-min($breakpoints-xl) {
              & {
                width: 50%;
                height: 29.6rem;
              }
            }
          }
          .imgbackgroundfiller {
            content: "";
            position: relative;
            background-color: none;
            width: 50%;
            height: 29.6rem;
            @include DisplayFlexCenter(row);
            align-self: center;
            @include media-breakpoint-min($breakpoints-xs) {
              & {
                height: 17rem;
                width: 30%;
              }
            }
            @include media-breakpoint-min($breakpoints-sm) {
              & {
                height: 17rem;
                width: 30%;
              }
            }
            @include media-breakpoint-min($breakpoints-md) {
              & {
                height: 17rem;
                width: 20%;
              }
            }
            @include media-breakpoint-min($breakpoints-lg) {
              & {
                height: 17rem;
                width: 15%;
              }
            }
            @include media-breakpoint-min($breakpoints-xl) {
              & {
                width: 50%;
                height: 29.6rem;
              }
            }
          }
        }
      }
    }
  }
}
