/* ------------------------------ //Fonts Types ----------------------------- */

// Dustin Fonts
$DustinRegular: "Dustin-Regular";
$DustinRegularOutline: "Dustin-Regular-Outline";
$DustinItalicOutline: "Dustin-Italic-OutLine";
$DustinItalic: "Dustin-Italic";
//Gudea Fonts
$GudeaBold: "Gudea-Bold";
$GudeaItalic: "Gudea-Italic";
$GudeaRegular: "Gudea-Regular";
//Hind Fonts
$HindRegular: "Hind-Regular";
$HindBold: "Hind-Bold";
//GTWalsheimPro Fonts
$GTWalsheimBold: "GTWalsheimPro-Bold";
//PlusJakarta Fonts
$PlusJakartaBold: "PlusJakarta-Bold";
$PlusJakartaRegular: "PlusJakarta-Regular";
//Anton Fonts
$AntonRegular: "Anton-Regular";

/* ------------------------------ //Font sizes ------------------------------ */
$Title-FontSize: clamp(2.8rem, 5vw, 4.2rem);
$Welcome-FontSize: clamp(0.9375rem, 2vw, 1.25rem);
$SmallHeader-Fontsize: 0.75rem;
$PrimaryHeader-FontSize: 2.3rem;
$SecondaryHeader-FontSize: 2rem;
$PrimaryText-FontSize: 1.125rem;
$SecondaryText-FontSize: 1rem;
$MobileTextSize: 14px;

//Font-face
@font-face {
  font-family: "Dustin-Italic-OutLine";
  src: url(../Assets/Fonts/Dustin/Dustin-Italic-Outline.ttf) format("truetype");
}
@font-face {
  font-family: "Dustin-Italic";
  src: url(../Assets/Fonts/Dustin/Dustin-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Dustin-Regular-Outline";
  src: url(../Assets/Fonts/Dustin/Dustin-Regular-Outline.ttf) format("truetype");
}
@font-face {
  font-family: "Dustin-Regular";
  src: url(../Assets/Fonts/Dustin/Dustin-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Gudea-Bold";
  src: url(../Assets/Fonts/Gudea/Gudea-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Gudea-Italic";
  src: url(../Assets/Fonts/Gudea/Gudea-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Gudea-Regular";
  src: url(../Assets/Fonts/Gudea/Gudea-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Hind-Regular";
  src: url(../Assets/Fonts/Hind/Hind-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Hind-Bold";
  src: url(../Assets/Fonts/Hind/Hind-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "GTWalsheimPro-Bold";
  src: url(../Assets/Fonts/GTWalsgeim/GTWalsheimPro-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "PlusJakarta-Bold";
  src: url(../Assets/Fonts/PlusJakarta/PlusJakartaSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "PlusJakarta-Regular";
  src: url(../Assets/Fonts/PlusJakarta/PlusJakartaSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Anton-Regular";
  src: url(../Assets/Fonts/Anton/Anton-Regular.ttf) format("truetype");
}
