@import "../../../Scss/index";

.content {
  display: flex;
  flex-direction: column;
  .label {
    color: $PrimaryHeaderColor;
    font-family: $GudeaBold;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .textarea {
    margin-top: 0.4rem;
    width: 100%;
    height: 10rem;
    background-color: $PrimaryBgColor;
    border: none;
    color: $PrimaryTextColor;
    font-family: $HindRegular;
    font-size: 16px;
    border-bottom: 1px solid #fff;
    resize: none;
    padding: 0rem;
    border-radius: 0;
    &:focus {
      outline: none;
      border-bottom: 1px solid $SecondaryBgColor;
    }
  }
  .textareaerror {
    border-bottom: 1px solid red !important;
    &:focus {
      outline: none;
      border-bottom: 1px solid $SecondaryBgColor;
    }
  }
}
