/* -------------------------------- //Colors -------------------------------- */
$PrimaryBgColor: #1a1a1a;
$SecondaryBgColor: #6300ff;
$ThirdBgColor: #7652b0;
$PrimaryHeaderColor: #fff;
$PrimaryTextColor: #bbbbbb;
$PrimaryNavTextColor: #b8b8b8;
$PrimaryPurpleLinear: linear-gradient(252deg, #7652b0, #4b08b5, #6300ff);
$PrimaryRedLinear: linear-gradient(252deg, #5e0000, #d60000, #c92a2a);
$ExtenedText-LinearBefore: linear-gradient(0deg, #1a1a1a, #1a1a1a00);
$ExtenedText-LinearAfter: linear-gradient(180deg, #1a1a1a, #1a1a1a3f);
$LinkHoverBg: #ffffff10;
$LinkHover: #ffffff25;
$BorderColor: #2b2b2b;

/* ------------------------------ //Breakpoints ----------------------------- */
$breakpoints-xs: 0;
$breakpoints-sm: 576px;
$breakpoints-md: 768px;
$breakpoints-lg: 992px;
$breakpoints-xl: 1200px;
$breakpoints-xxl: 1400px;

/* --------------------------------- //Grid --------------------------------- */

$grid-columns: 12;
$grid-row-margin: 12%;

/* --------------------------------- //Fonts -------------------------------- */
