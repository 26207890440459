@import "../../Scss/index";

.contentbody {
  position: relative;
  @include DisplayFlexCenter(column);
  align-items: flex-start;
  width: 100%;
  margin-inline: 10%;
  height: 100%;
  @include media-breakpoint-min($breakpoints-xs) {
    & {
      margin-inline: 10%;
      margin-bottom: 8rem;
    }
  }
  @include media-breakpoint-min($breakpoints-sm) {
    & {
      margin-inline: 10%;
      margin-bottom: 4rem;
    }
  }
  @include media-breakpoint-min($breakpoints-md) {
    & {
      margin-inline: 10%;
    }
  }
  @include media-breakpoint-min($breakpoints-xl) {
    & {
      margin-inline: 0%;
      margin-bottom: 0rem;
    }
  }
  .header {
    @include DisplayFlexCenter(row);
    margin: 0rem;
    .bulletpoint {
      content: "";
      width: 30px;
      height: 5px;
      background: $PrimaryPurpleLinear;
      border-radius: 5px;
      margin-right: 1rem;
    }
    .title {
      font-family: $DustinRegular;
      color: $PrimaryHeaderColor;
      font-size: $PrimaryHeader-FontSize;
      letter-spacing: 1px;
      font-weight: lighter;
      @include media-breakpoint-min($breakpoints-xs) {
        & {
          font-size: 1.6rem;
        }
      }
      @include media-breakpoint-min($breakpoints-lg) {
        & {
          font-size: $PrimaryHeader-FontSize;
        }
      }
    }
  }
  .content {
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: 1s ease-in-out;
    .aboutmetext {
      margin: 0rem;
      color: $PrimaryTextColor;
      font-size: $PrimaryText-FontSize;
      line-height: 2.125rem;
      @include media-breakpoint-min($breakpoints-xs) {
        & {
          font-size: $MobileTextSize;
          line-height: 1.7rem;
        }
      }
      @include media-breakpoint-min($breakpoints-lg) {
        & {
          line-height: 2.125rem;
          font-size: $PrimaryText-FontSize;
        }
      }
    }
    .fade {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: $ExtenedText-LinearBefore;
    }
  }
  .contentclosed {
    height: 11rem;
  }
  .btnpostion {
    @include DisplayFlexCenter(row);
    width: 100%;
    margin-bottom: 1rem;
    .openicon {
      cursor: pointer;
      align-items: center;
      width: 2.3rem;
      height: 2.3rem;
      transition: transform 0.2s;
      &:hover {
        background: $LinkHover;
        border-radius: 10px;
        transform: translateY(2px);
      }
    }
    .openiconrotate {
      cursor: pointer;
      align-items: center;
      width: 2.3rem;
      height: 2.3rem;
      transform: rotate(180deg);
      transition: transform 0.2s;
      &:hover {
        background: $LinkHover;
        border-radius: 10px;
        transform: translateY(2px);
        transform: rotate(180deg);
      }
    }
  }
  .medialinks {
    @include DisplayFlexCenter(row);
    margin: 0rem;
    padding: 0rem;
    list-style: none;
    margin-bottom: 2.3rem;
    margin-left: -3px;
    margin-top: 2rem;
    .mediaitems {
      margin-right: 1rem;
      .icon {
        width: 31px;
        height: 31px;
        padding: 3px;
        &:hover {
          background-color: $LinkHover;
          border-radius: 10px;
        }
      }
    }
  }
}
