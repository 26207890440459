@import "../../../Scss/index";

.content {
  display: flex;
  flex-direction: column;
  .label {
    color: $PrimaryHeaderColor;
    font-family: $GudeaBold;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .input {
    margin-top: 0.4rem;
    width: 100%;
    height: 2rem;
    background-color: $PrimaryBgColor;
    border: none;
    color: $PrimaryTextColor;
    font-family: $HindRegular;
    font-size: 16px;
    height: 3rem;
    border-bottom: 1px solid #fff;
    padding: 0rem;
    border-radius: 0;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: #505050;
      -webkit-box-shadow: 0 0 0px 1000px $PrimaryBgColor inset;
      box-shadow: 0 0 0px 1000px $PrimaryBgColor inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    &:-webkit-autofill::first-line {
      font-family: $HindRegular;
    }
    &:focus {
      outline: none;
      border-bottom: 1px solid $SecondaryBgColor;
    }
  }
  .inputerror {
    border-bottom: 1px solid red !important;
    &:focus {
      outline: none;
      border-bottom: 1px solid $SecondaryBgColor;
    }
  }
}
