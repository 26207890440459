@import "../../Scss/index";

.headercontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem;
  @include media-breakpoint-max($breakpoints-sm) {
    & {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0rem;
      text-align: center;
    }
  }
  .header {
    @include DisplayFlexCenter(row);
    justify-content: flex-start;
    .bulletpoint {
      content: "";
      width: 30px;
      height: 5px;
      background: $PrimaryPurpleLinear;
      border-radius: 5px;
      margin-right: 1rem;
    }
    .welcometext {
      font-family: $HindBold;
      font-size: 1.4rem;
      letter-spacing: 0px;
      color: $PrimaryHeaderColor;
      margin: 0rem;
      @include media-breakpoint-min($breakpoints-xs) {
        & {
          font-size: 1rem;
        }
      }
      @include media-breakpoint-min($breakpoints-sm) {
        & {
          font-size: 1.4rem;
        }
      }
    }
  }
  .title {
    font-family: $GudeaBold;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: $PrimaryHeaderColor;
    font-size: 3.5rem;
    margin: 0rem;
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
    letter-spacing: 1px;
    font-weight: lighter;
    @include media-breakpoint-min($breakpoints-xs) {
      & {
        font-size: 2rem;
        text-align: center;
      }
    }
    @include media-breakpoint-min($breakpoints-sm) {
      & {
        font-size: 3.5rem;
        text-align: start;
      }
    }
  }
  .smallheader {
    font-size: 16px;
    font-family: $HindRegular;
    color: $PrimaryTextColor;
    font-weight: lighter;
    margin: 0rem;
    margin-bottom: 2rem;
  }
}
.btnpostion {
  display: flex;
}
.titlecolor {
  color: $SecondaryBgColor;
}

@include media-breakpoint-min($breakpoints-xs) {
  .headercontainer {
    align-items: center;
  }
}
@include media-breakpoint-min($breakpoints-sm) {
}
@include media-breakpoint-min($breakpoints-lg) {
  .headercontainer {
    align-items: flex-start;
  }
}
