@import "../../Scss/index";

.container {
  @include Container();
  @include DisplayFlexCenter(column);
  @include media-breakpoint-max($breakpoints-xl) {
    & {
      justify-content: flex-start;
      padding-block: 6rem;
      height: fit-content;
    }
  }
  position: relative;
  .header {
    @include DisplayFlexCenter(row);
    margin: 0rem;
    .bulletpoint {
      content: "";
      width: 30px;
      height: 5px;
      background: $PrimaryPurpleLinear;
      border-radius: 5px;
      margin-right: 1rem;
    }
    .title {
      font-family: $DustinRegular;
      color: $PrimaryHeaderColor;
      font-size: $PrimaryHeader-FontSize;
      letter-spacing: 1px;
      font-weight: lighter;
    }
  }
  .row {
    @include make-row();
    margin-top: 0rem;
    @include media-height-breakpoint-max(744px) {
      & {
        margin-top: 8rem;
      }
    }
    @include media-breakpoint-max($breakpoints-xl) {
      & {
        margin-top: 0rem;
      }
    }
    .col_3 {
      @include make-col(xs, 12);
      @include make-col(sm, 12);
      @include make-col(md, 12);
      @include make-col(lg, 12);
      @include make-col(xl, 3);
      @include make-col(xxl, 3);
      @include DisplayFlexCenter(row);
      .navlist {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: none;
        margin: 0rem;
        padding: 0rem;
        list-style: none;
        user-select: none;
        @include media-breakpoint-max($breakpoints-xl) {
          flex-direction: row;
          align-items: center;
        }
      }
    }
    .col_9 {
      @include make-col(xs, 12);
      @include make-col(sm, 12);
      @include make-col(md, 12);
      @include make-col(lg, 12);
      @include make-col(xl, 9);
      @include make-col(xxl, 9);
    }
  }
}
