@import "../../../Scss/index";

.body {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $PrimaryBgColor;
  z-index: 1000;
  overflow-y: scroll;
  .topbar {
    display: block;
    width: 100%;
    height: 7px;
    background: $PrimaryPurpleLinear;
  }
  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $PrimaryHeaderColor;
    padding-inline: 2rem;
    background-color: $PrimaryBgColor;
    border-bottom: 1px solid #2b2b2b;
    .title {
      font-family: $HindBold;
      font-size: 1rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
    .exiticon {
      width: 27px;
      height: 27px;
      padding: 5px;
      &:hover {
        background-color: $LinkHover;
        border-radius: 10px;
      }
    }
  }
  .imglist {
    margin: 0rem;
    padding: 0rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    list-style: none;

    .imgitem {
      width: 85%;
      margin: 0.5rem;
      .img {
        width: 100%;
        border-radius: 4px;
      }
    }
  }
  .btncontent {
    @include DisplayFlexCenter(row);
    margin-top: 1rem;
    margin-bottom: 8rem;
  }
}
