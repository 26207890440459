@import "../../Scss/index";

.navlink,
.navlinkactive {
  margin-inline: 0.5rem;
  text-decoration: none;
  position: relative;
  border-radius: 10px;
  .navitem {
    position: relative;
    @include DisplayFlexCenter(row);
    padding-inline: 0.5rem;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .icon {
    height: 22px;
    width: 22px;
  }
  .navtext {
    font-size: 14px;
    margin: 0rem;
    font-family: $GudeaBold;
    color: $PrimaryNavTextColor;
    padding-left: 0.3rem;
  }
  &:hover {
    transition: 0.2s;
    background: $LinkHover;
  }
  &.navlinkactive::after {
    content: "";
    bottom: -18px;
    z-index: 1;
    position: absolute;
    border-radius: 5px;
    width: 100%;
    height: 5px;
    background: $PrimaryPurpleLinear;
    pointer-events: none;
    transform: scalex(1);
    animation: scale-width 300ms;
  }
}

@keyframes scale-width {
  0% {
    transform: scalex(0%);
  }
  100% {
    transform: scalex(100%);
  }
}
