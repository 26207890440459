@import "../../../Scss/index";

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .imgview {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 3.5rem;
    height: 27rem;
    @include media-breakpoint-min($breakpoints-lg) {
      & {
        height: 14rem;
      }
    }
    @include media-breakpoint-min($breakpoints-xl) {
      & {
        height: 20rem;
      }
    }
    @include media-breakpoint-min($breakpoints-xxl) {
      & {
        height: 27rem;
      }
    }
    .arrowIcon {
      z-index: 4;
      width: 32px;
      height: 32px;
      padding-inline: 0.1rem;
      margin-inline: 3.2rem;
      border-radius: 10px;
      &:hover {
        background-color: $LinkHover;
      }
      @include media-breakpoint-min($breakpoints-lg) {
        & {
          margin-inline: 2.1rem;
        }
      }
      @include media-breakpoint-min($breakpoints-xl) {
        & {
          margin-inline: 2.9rem;
        }
      }
      @include media-breakpoint-min($breakpoints-xxl) {
        & {
          margin-inline: 3.2rem;
        }
      }
    }
    .photolist {
      position: relative;
      padding: 0rem;
      margin: 0rem;
      list-style: none;
      .photoitem {
        display: inline;
        position: relative;
      }
      .photoactive {
        position: relative;
        transform: scale(1.05);
        transition-duration: 0.5s;
      }
      .middlephoto {
        position: relative;
        display: flex;
        border-radius: 5px;
        z-index: 3;
        width: 55rem;
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        @include media-breakpoint-min($breakpoints-lg) {
          & {
            width: 32rem;
          }
        }
        @include media-breakpoint-min($breakpoints-xl) {
          & {
            width: 42rem;
          }
        }
        @include media-breakpoint-min($breakpoints-xxl) {
          & {
            width: 55rem;
          }
        }
      }
      .overlaymiddle {
        z-index: 4;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000d3;
        border-radius: 5px;
      }
      .overlaytext {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $PrimaryHeaderColor;
        font-family: $GudeaBold;
        font-size: 3.4rem;
        @include media-breakpoint-min($breakpoints-lg) {
          & {
            font-size: 1.8rem;
          }
        }
        @include media-breakpoint-min($breakpoints-xl) {
          & {
            font-size: 2.8rem;
          }
        }
        @include media-breakpoint-min($breakpoints-xxl) {
          & {
            font-size: 3.4rem;
          }
        }
      }
    }
  }
  .context {
    padding: 0rem;
    margin-top: 3rem;
    @include DisplayFlexCenter(row);
    list-style: none;
    li {
      margin-inline: 2rem;
    }
  }
}
.bgblock {
  position: absolute;
  content: "";
  background: $SecondaryBgColor;
  left: 0%;
  width: 14rem;
  height: 100%;
  z-index: 0;
  @include media-breakpoint-min($breakpoints-lg) {
    & {
      width: 8rem;
    }
  }
  @include media-breakpoint-min($breakpoints-xl) {
    & {
      width: 10rem;
    }
  }
  @include media-breakpoint-min($breakpoints-xxl) {
    & {
      width: 14rem;
    }
  }
}
