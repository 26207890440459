@import "../../../Scss/index";

.body {
  position: relative;
  @include DisplayFlexCenter(row);
  height: 100%;
  .row {
    position: relative;
    @include make-row();
    .col {
      position: relative;
      @include make-col(xs, 12);
      @include make-col(sm, 12);
      @include make-col(md, 4);
      @include make-col(lg, 4);
      @include make-col(xl, 4);
      @include make-col(xxl, 4);
      @include DisplayFlexCenter(row);
      align-items: flex-start;
      .list {
        position: relative;
        margin: 0rem;
        list-style: none;
        padding: 0rem;
        margin-bottom: 3rem;
        .item {
          display: flex;
          justify-content: center;
          color: $PrimaryTextColor;
          padding: 0.5rem;
          padding-inline: 2rem;
        }
        .itemtitle {
          width: 7rem;
          display: flex;
          justify-content: center;
          border-left: 1px solid $SecondaryBgColor;
          border-right: 1px solid $SecondaryBgColor;
          padding: 0.5rem;
          color: $PrimaryHeaderColor;
          font-weight: bold;
          padding-inline: 2rem;
        }
      }
    }
  }
}
