@import "../../Scss/index";

.postion {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0%, -50%);
  .navlist {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0rem;
    padding: 0rem;
    list-style: none;
    user-select: none;
    .navitem {
      position: relative;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      user-select: none;
      @include media-breakpoint-min($breakpoints-lg) {
        & {
          padding: 0.8rem;
          padding-left: 0.7rem;
        }
      }
      @include media-breakpoint-min($breakpoints-xl) {
        & {
          padding: 0.9rem;
          padding-left: 0.8rem;
        }
      }
      @include media-breakpoint-min($breakpoints-xxl) {
        & {
          padding: 1rem;
        }
      }
      &:hover .dot {
        background-color: #b1b1b1;
      }
      &:hover .title {
        color: #b1b1b1;
      }
      .title {
        padding-left: 1rem;
        color: $LinkHover;
        font-family: $GudeaBold;
        font-size: 1rem;
        transition: color ease-in-out 0.2s;
        cursor: pointer;
        @include media-breakpoint-min($breakpoints-lg) {
          & {
            font-size: 0.8rem;
            padding-left: 0.7rem;
          }
        }
        @include media-breakpoint-min($breakpoints-xl) {
          & {
            font-size: 0.9rem;
            padding-left: 0.8rem;
          }
        }
        @include media-breakpoint-min($breakpoints-xxl) {
          & {
            font-size: 1rem;
            padding-left: 1rem;
          }
        }
      }
    }
    .navitemactive {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: $LinkHoverBg;
      padding: 0.5rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding-inline: 1rem;
      border-radius: 25px;
      height: 100%;
      @include media-breakpoint-min($breakpoints-lg) {
        & {
          padding: 0.3rem;
          padding-inline: 0.7rem;
        }
      }
      @include media-breakpoint-min($breakpoints-xl) {
        & {
          padding: 0.4rem;
          padding-inline: 0.8rem;
        }
      }
      @include media-breakpoint-min($breakpoints-xxl) {
        & {
          padding: 0.5rem;
          padding-inline: 1rem;
        }
      }
      width: fit-content;
      cursor: default;
      .activedot {
        position: relative;
        content: "";
        width: 12px;
        height: 12px;
        background-color: $SecondaryBgColor;
        border-radius: 25px;
        @include media-breakpoint-min($breakpoints-lg) {
          & {
            width: 8px;
            height: 8px;
          }
        }
        @include media-breakpoint-min($breakpoints-xl) {
          & {
            width: 10px;
            height: 10px;
          }
        }
        @include media-breakpoint-min($breakpoints-xxl) {
          & {
            width: 12px;
            height: 12px;
          }
        }
      }
      .title {
        font-size: 1rem;
        padding-left: 1rem;
        color: $PrimaryHeaderColor;
        font-family: $GudeaBold;
        @include media-breakpoint-min($breakpoints-lg) {
          & {
            font-size: 0.8rem;
            padding-left: 0.7rem;
          }
        }
        @include media-breakpoint-min($breakpoints-xl) {
          & {
            font-size: 0.9rem;
            padding-left: 0.8rem;
          }
        }
        @include media-breakpoint-min($breakpoints-xxl) {
          & {
            font-size: 1rem;
            padding-left: 1rem;
          }
        }
      }
    }
    .dot {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $LinkHover;
      transition: background-color ease-in-out 0.2s;
      @include media-breakpoint-min($breakpoints-lg) {
        & {
          width: 8px;
          height: 8px;
        }
      }
      @include media-breakpoint-min($breakpoints-xl) {
        & {
          width: 10px;
          height: 10px;
        }
      }
      @include media-breakpoint-min($breakpoints-xxl) {
        & {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
