@import "../../Scss/index";

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  .bgblock {
    position: absolute;
    content: "";
    background: $SecondaryBgColor;
    left: 0%;
    width: 14rem;
    height: 100%;
    z-index: 0;
  }
}
