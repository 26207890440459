@import "../../Scss/index";

.container {
  @include DisplayFlexCenter(row);
  @include Container;
  @include media-breakpoint-min($breakpoints-xs) {
    & {
      height: 100%;
    }
  }
  @include media-breakpoint-min($breakpoints-sm) {
    & {
      height: 100vh;
    }
  }
}
.row {
  @include make-row();
  align-items: center;
  .col_header {
    @include make-col(xs, 12);
    @include make-col(sm, 12);
    @include make-col(md, 12);
    @include make-col(lg, 7);
    @include make-col(xl, 7);
    @include make-col(xxl, 7);
  }
  .col_img {
    @include make-col(xs, 12);
    @include make-col(sm, 12);
    @include make-col(md, 12);
    @include make-col(lg, 5);
    @include make-col(xl, 5);
    @include make-col(xxl, 5);
  }
}
