@import "../../Scss/index";

.moblienavlogopostion {
  padding-inline: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #2b2b2b;
  background-color: $PrimaryBgColor;
  width: 100%;
  padding-inline: 1rem;
  .mobilenavpage {
    position: relative;
    left: 0;
    font-family: $GudeaBold;
    color: $PrimaryHeaderColor;
    font-size: 16px;
  }
  .mobilennavtitle {
    position: relative;
    font-family: $GudeaBold;
    color: $PrimaryTextColor;
    font-size: 16px;
  }
  .mobilenavbar {
    @include DisplayFlexCenter(row);
    height: 65px;
  }
}

.mobilenav {
  padding-inline: 1rem;
  position: fixed;
  bottom: 4%;
  left: 50%;
  transform: translate(-50%);
  z-index: 1000;
  .navlogo {
    position: absolute;
    padding-inline: 1rem;
    left: 0;
    font-family: $GudeaBold;
    color: $PrimaryNavTextColor;
    font-size: 16px;
    display: none;
  }
  .navbar {
    @include DisplayFlexCenter(row);
    height: 65px;
    width: fit-content;
    padding-inline: 2.2rem;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
      rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
      rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: $PrimaryBgColor;
  }
  .navlist {
    @include DisplayFlexCenter(row);
    margin: 0rem;
    padding: 0rem;
  }
}

.nav {
  padding-inline: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 901;
  border-bottom: 1px solid #2b2b2b;
  background-color: $PrimaryBgColor;
  .navlogo {
    position: absolute;
    padding-inline: 1rem;
    left: 0;
    font-family: $GudeaBold;
    color: $PrimaryNavTextColor;
    font-size: 16px;
  }
  .navbar {
    @include DisplayFlexCenter(row);
    height: 65px;
  }
  .navlist {
    @include DisplayFlexCenter(row);
    margin: 0rem;
    padding: 0rem;
  }
}
